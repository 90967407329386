<template>
  <div>
    <Toast position="top-right" />

    <div class="card">
      <div class="card-body p-4">
        <div class="aut-area-exibir">
          <div class="configuracao-menu-container">
            <div class="header-menus-container">
              <h3>
                Informações da IA -
                <span style="color: #ffa800">Letícia</span>
              </h3>
              <div class="botoes-container">
                <b-button
                  variant="success mr-2"
                  @click="salvarInformacoesRecursoIA"
                >
                  Salvar Alterações
                </b-button>
                <b-button
                  class="btn-danger"
                  variant="primary"
                  @click="descartarAlteracoes"
                  >Descartar</b-button
                >
              </div>
            </div>

            <div>
              <div class="flexer-container">
                <div class="form-group w-100">
                  <label for="ira_endereco">
                    Qual o endereço da clínica?
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-textarea
                    type="text"
                    class="form-control"
                    id="ira_endereco"
                    rows="3"
                    v-model="informacoes_recurso_ia.ira_endereco"
                  />
                </div>
                <div class="form-group w-100">
                  <label for="ira_especialidades">
                    Quais especialidades são atendidas?
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-textarea
                    type="text"
                    class="form-control"
                    id="ira_especialidades"
                    rows="3"
                    v-model="informacoes_recurso_ia.ira_especialidades"
                  />
                </div>
                <div class="form-group w-100">
                  <label for="ira_horario_funcionamento">
                    Qual o horário de funcionamento?
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-textarea
                    type="text"
                    class="form-control"
                    id="ira_horario_funcionamento"
                    v-model="informacoes_recurso_ia.ira_horario_funcionamento"
                    rows="3"
                  />
                </div>
              </div>
              <div class="flexer-container">
                <div class="form-group w-100">
                  <label for="ira_planos_saude">
                    Quais os planos de saúde aceitos?
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-textarea
                    type="text"
                    class="form-control"
                    id="ira_planos_saude"
                    rows="3"
                    v-model="informacoes_recurso_ia.ira_planos_saude"
                  />
                </div>
                <div class="form-group w-100">
                  <label for="ira_corpo_clinico">
                    Qual o corpo clínico?
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-textarea
                    type="text"
                    class="form-control"
                    id="ira_corpo_clinico"
                    rows="3"
                    v-model="informacoes_recurso_ia.ira_corpo_clinico"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

import ApiService from "@/core/services/api.service";
import API_LINKS from "../automacao/api.links";

export default {
  components: {
    Toast: () => import("primevue/toast")
  },
  data() {
    return {
      informacoes_recurso_ia: {
        ira_endereco: "",
        ira_especialidades: "",
        ira_horario_funcionamento: "",
        ira_planos_saude: "",
        ira_corpo_clinico: ""
      },
      informacoes_recurso_ia_iniciais: {}
    };
  },
  methods: {
    descartarAlteracoes() {
      if (!this.houveAlgumaMudanca) {
        return;
      }
      Swal.fire({
        title: "Descartar Alterações?",
        text: "Você tem certeza que deseja descartar as alterações?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, descartar!"
      }).then((result) => {
        if (result.value) {
          this.informacoes_recurso_ia = {
            ...this.informacoes_recurso_ia_iniciais
          };
        }
      });
    },

    async salvarInformacoesRecursoIA() {
      Swal.fire({
        title: "Salvar Alterações?",
        text: "Você tem certeza que deseja salvar as alterações?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, salvar!"
      }).then(async (result) => {
        if (result.value) {
          const payload = {
            informacoes_recurso_ia: {
              ...this.informacoes_recurso_ia
            }
          };
          await ApiService.put(
            API_LINKS.chatbot_informacoes_recurso_ia,
            payload
          );
          this.informacoes_recurso_ia_iniciais = {
            ...this.informacoes_recurso_ia
          };
          Swal.fire({
            title: "Alterações salvas com sucesso!",
            icon: "success",
            showConfirmButton: false,
            timer: 1000
          });
        }
      });
    },
    async buscarInformacoesRecursoIA() {
      const response = await ApiService.get(
        API_LINKS.chatbot_informacoes_recurso_ia
      );
      const informacoes_recurso_ia = response.data.informacoes_recurso_ia;
      if (!informacoes_recurso_ia) {
        const payload = {
          informacoes_recurso_ia: { ...this.informacoes_recurso_ia }
        };
        const res = await ApiService.post(
          API_LINKS.chatbot_informacoes_recurso_ia,
          payload,
          false
        );
        this.informacoes_recurso_ia = res.data.informacoes_recurso_ia;
        this.informacoes_recurso_ia_iniciais = {
          ...this.informacoes_recurso_ia
        };
      } else {
        this.informacoes_recurso_ia = informacoes_recurso_ia;
        this.informacoes_recurso_ia_iniciais = {
          ...this.informacoes_recurso_ia
        };
      }
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Chatbot"
      },
      {
        title: "Opções",
        route: "/painel/chatbot/opcoes"
      }
    ]);
    const init = async () => {
      this.buscarInformacoesRecursoIA();
    };
    init();
  },
  computed: {
    houveAlgumaMudanca() {
      return (
        JSON.stringify(this.informacoes_recurso_ia) !=
        JSON.stringify(this.informacoes_recurso_ia_iniciais)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

.b-button-custom {
  width: 142px;
}

.aut-body {
  border-radius: 5px;
  background-color: #fff;
}

.aut-area-exibir {
  display: flex;
  height: 100%;
  width: 100%;
}

.aut-header {
  height: 70px;
  width: 100%;
}

.aut-camp-area {
  width: 100%;
  min-height: 500px;
}

.container-dashboard {
  border-radius: 15px;
  background-color: #fff;
  padding: 20px 10px 20px 10px;
  margin-bottom: 15px;
}

.campo-estatistica {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.dropdown-form {
  padding: 3px 0px;
  width: 170px;
  height: 45px;
}

.dropdown-form:hover {
  background-color: rgb(247, 247, 247);
}

.button-container {
  border: 1px solid #e4e6ef;
  outline: none;
  color: #e3e4ed;
  background-color: transparent;
  border-radius: 0.42rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 38px;
}
.button-container.btn i {
  padding: 0 !important;
}

::v-deep .p-chips .p-chips-multiple-container {
  width: 100% !important;
}
::v-deep .p-chips-multiple-container {
  flex-wrap: wrap !important;
  gap: 0.5rem !important;
}
/*
::v-deep .p-selectbutton .p-button {
  background: #c4c4c4 !important;
  border-color: #c4c4c4 !important;

  &.p-highlight {
    background: #ffc107 !important;
    border-color: #ffc107 !important;
    color: #212121 !important;
  }
}
*/
::v-deep .select-button-ativado .p-button {
  background: #ffffff !important;
  border-color: #ced4da !important;
  color: #000000 !important;

  &.p-highlight {
    background: #1bc5bd !important;
    border-color: #1bc5bd !important;
    color: #ffffff !important;
  }
}

::v-deep .select-button-inativado .p-button {
  background: #ffffff !important;
  border-color: #ced4da !important;
  color: #000000 !important;

  &.p-highlight {
    background: #ee2d41 !important;
    border-color: #ee2d41 !important;
    color: #ffffff !important;
  }
}

.configuracao-menu-container {
  width: 100%;

  .header-menus-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h3 {
      margin: 0;
    }
    .botoes-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .flexer-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    min-height: 100% !important;
  }

  .flexer-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100% !important;

    > .form-group:first-child {
      margin-bottom: 0 !important;
    }
  }

  .lista-teste span {
    color: rgb(75, 112, 190);
    font-weight: bold;
  }
  .lista-teste i {
    color: blue;
    margin-right: 10px;
  }

  .lista-negra span {
    color: rgb(226, 77, 77);
    font-weight: bold;
  }
  .lista-negra i {
    color: red;
    margin-right: 10px;
  }
  hr {
    margin-left: 1%;
    width: 98%;
    margin-bottom: 30px;
    margin-top: -10px;
  }
}
</style>
